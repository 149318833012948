.container_width {
  width: 30%;
}
body {
  background-color: #f8f9f5;
}

@media screen and (max-width: 1200px) {
  .container_width {
    width: 50%;
  }
}
@media screen and (max-width: 800px) {
  .container_width {
    width: 70%;
  }
}
@media screen and (max-width: 500px) {
  .container_width {
    width: 90%;
  }
}
